import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyCwnMBpL9JX0cUxd9yAunE4hDr8y-j1__o",
    authDomain: "slack-clone-62673.firebaseapp.com",
    projectId: "slack-clone-62673",
    storageBucket: "slack-clone-62673.appspot.com",
    messagingSenderId: "795788301738",
    appId: "1:795788301738:web:6a4e6161ed1f5b94f2c7d3"
};

const firebaseApp = firebase.initializeApp(firebaseConfig)
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export {auth, provider}
export default db;
